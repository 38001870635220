@import "src/styles/_variables-mixins.scss";
.header {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 88px;
  padding: 0 80px;

  background-color: var(--mobility-color-light-50);

  & > nav {
    height: 100%;
  }

  &--black {
    background-color: var(--mobility-color-dark-950);
  }
  &--orange {
    background-color: var(--mobility-color-orange-600);

    :global(.menu-item:hover) {
      :global(.text-link) {
        border-color: var(--mobility-color-black-950);
      }
    }
  }

  &--fixed {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;

    background-color: transparent !important;
  }

  @include mediaQuery($xxl) {
    padding: 0 60px;
  }
  @include mediaQuery($xl) {
    padding: 0 40px;
  }
}

.logo {
  display: flex;
  position: relative;
  z-index: 101;
  max-width: 5em;

  @include mediaQuery($xl) {
    width: 15em;
  }
}
.actionsMenu {
  position: relative;
  z-index: 101;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;

  width: 20%;
  min-width: 268px;

  img {
    border-radius: 20px;
  }

  @include mediaQuery($xl) {
    min-width: 258px;
    gap: 30px;
  }
}

.menu {
  display: flex;
  gap: 0 24px;

  height: 100%;
}
.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 10px 12px;

  color: var(--mobility-color-dark-950);

  cursor: pointer;

  & > .textLink {
    position: relative;
    z-index: 101;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 40px;

    font-size: calcFontSize(16px);

    border-bottom: 2px solid transparent;

    & > span {
      padding-left: 8px;
      font-size: 20px;
    }
  }

  &:hover {
    & > .textLink {
      border-color: var(--mobility-color-orange-600);
    }
  }

  &--inverse {
    color: var(--mobility-color-light-50);
  }
}

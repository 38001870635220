@import "src/styles/_variables-mixins.scss";
.banner {
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;

  width: 100%;
  height: 786px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  h1 {
    position: relative;
    z-index: 10;

    width: 100%;
    max-width: 490px;

    color: var(--mobility-color-light-50);
    line-height: 1.3em;
    font-weight: 700;
    @include responsiveFontSize(96px, 72px, 48px);
  }
  h4 {
    position: relative;
    z-index: 11;

    color: var(--mobility-color-light-50);
    font-weight: 500;
    @include responsiveFontSize(20px, 20px, 18px);
  }

  @include mediaQuery($lg) {
    padding: 0 30px;
  }
  @include mediaQuery($md) {
    height: 545px;
  }
  @include mediaQuery($sm) {
    background-position: bottom 0 right -280px;
  }
}

.bannerVector {
  display: none;
  position: absolute;
  z-index: 2;

  left: -100px;
  bottom: 0px;

  width: 494px;
  height: 408px;

  background-image: url('data:image/svg+xml,<svg width="794" height="708" viewBox="0 0 794 708" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M794 451C794 540.199 767.549 627.396 717.993 701.562C668.436 775.729 598 833.535 515.59 867.67C433.181 901.805 342.5 910.736 255.014 893.334C167.529 875.932 87.1683 832.979 24.0948 769.905C-38.9786 706.832 -81.9322 626.471 -99.3342 538.986C-116.736 451.5 -107.805 360.819 -73.6697 278.41C-39.5345 196 18.2713 125.564 92.4378 76.0072C166.604 26.4507 253.801 -1.06369e-06 343 0V115.446C276.634 115.446 211.758 135.126 156.576 171.997C101.394 208.868 58.3856 261.274 32.9883 322.589C7.59095 383.903 0.945854 451.372 13.8933 516.463C26.8407 581.555 58.7992 641.345 105.727 688.273C152.655 735.201 212.445 767.159 277.537 780.107C342.628 793.054 410.097 786.409 471.411 761.012C532.726 735.614 585.132 692.606 622.003 637.424C658.874 582.242 678.554 517.366 678.554 451H794Z" fill="%23FF5C00"/></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;

  @include mediaQuery($sm) {
    display: block;
  }
}

.searchCategories {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;

    height: 440px;
    background-color: var(--mobility-color-orange-600);
  }

  :global(.search-component) {
    margin-top: -60px;
  }

  @include mediaQuery($sm) {
    :global(.container) {
      padding: 0;
    }

    :global(.search-component) {
      margin-top: 0px;
    }

    &:before {
      height: 760px;
    }
  }
}

.categoriesWrapper {
  padding-top: 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 40px;

    h2 {
      color: var(--mobility-color-light-50);
      font-weight: 700;
      @include responsiveFontSize(40px, 36px, 36px);
      line-height: 1.35em;
    }
    h5 {
      color: var(--mobility-color-light-50);
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    & > * {
      flex-grow: 1;
    }
  }

  @include mediaQuery($xl) {
    &__header {
      hgroup {
        max-width: 70%;
      }
    }
  }
  @include mediaQuery($md) {
    &__list {
      flex-direction: column;

      margin-top: 24px;

      & > * {
        margin-bottom: 24px;

        p {
          margin-top: 12px;
        }
      }
    }
  }
  @include mediaQuery($sm) {
    padding: 80px 15px 0 15px;

    &__header {
      flex-direction: column;
      align-items: flex-start;

      width: 100%;
      margin-bottom: 40px;

      hgroup {
        max-width: 100%;
      }

      :global(.button) {
        margin-top: 24px;
        padding: 0;

        &:hover {
          background-color: transparent !important;
          border-color: transparent !important;
          color: var(--mobility-color-dark-950) !important;
        }
      }
    }
  }
}

.advantages {
  display: flex;
  align-items: center;
  gap: 22px;

  width: 100%;
  margin: 144px 0;

  li {
    display: flex;
    align-items: center;
    flex-grow: 1;

    width: 33.33%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 90px;
    height: 90px;

    border-radius: 9px;
    border: 2px solid var(--mobility-color-orange-700);

    & > span {
      width: 65%;
      height: 65%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding-left: 24px;

    h4 {
      line-height: 1.2em;
      font-weight: 600;
      color: var(--mobility-color-dark-950);
      @include responsiveFontSize(24px, 21px, 20px);
    }
    p {
      color: var(--mobility-color-dark-400);
      font-weight: 500;
      @include responsiveFontSize(16px, 16px, 14px);
    }
  }

  @include mediaQuery($xl) {
    &__icon {
      width: 72px;
      height: 72px;
    }
  }
  @include mediaQuery($md) {
    flex-direction: column;

    margin: 80px 0 120px 0;

    li {
      flex-direction: column;
      align-items: center;

      width: 100%;
      margin-bottom: 24px;

      h4 {
        margin-top: 24px;
      }

      & > * {
        text-align: center;
      }
    }

    &__icon {
      width: 90px;
      height: 90px;
    }
  }
}

.aboutUs {
  display: flex;
  align-items: center;
  gap: 88px;

  width: 100%;
  margin-bottom: 140px;

  &__col-left {
    display: flex;
    flex-direction: column;
    gap: 48px;

    width: 50%;
    flex-shrink: 0;

    h2 {
      width: 90%;
    }
    h4 {
      width: 78%;
    }
  }

  &__col-right {
    p {
      margin-bottom: 40px;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;
    gap: 40px;

    &__col-left,
    &__col-right {
      width: 100%;
    }
    &__col-left {
      gap: 12px;
    }

    :global(.button) {
      width: 100%;
    }
  }
}

.forYou {
  position: relative;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 140px;

  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: 50% !important;
    min-height: 100%;

    object-fit: cover;
  }

  &__col-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 50%;
    padding: 93px 80px;
    margin-left: 50%;

    p {
      margin: 16px 0 40px 0;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;
    gap: 40px;

    img,
    &__col-right {
      width: 100% !important;
    }

    img {
      position: relative;

      height: auto;
    }

    &__col-right {
      padding: 30px;
      margin: 0;
    }

    :global(.button) {
      width: 100%;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.carFeatures {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  width: 100%;

  :global(.icon) {
    line-height: 0;
  }

  &__icon-img {
    position: relative;
    display: block;

    width: 16px;
    height: 16px;

    & > span {
      width: 16px;
      height: 16px;
    }
  }

  li {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-size: calcFontSize(12px);
      font-weight: 500;
      color: var(--mobility-color-dark-700);
    }
  }
}

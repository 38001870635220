@import "src/styles/_variables-mixins.scss";
.checkoutContainer {
  @include mediaQuery($lg) {
    padding: 0;
  }
}

.checkout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;

  width: 100%;
  padding-bottom: 240px;

  &__content {
    display: flex;
    gap: 24px;
    flex-grow: 1;
    flex-wrap: wrap;

    width: 100%;

    :global(.checkout-card-item) {
      width: calc(50% - 12px);
      min-width: 340px;
      max-width: 440px;
    }
  }

  @include mediaQuery($lg) {
    flex-direction: column;

    padding-bottom: 0px;

    &__content {
      justify-content: center;

      padding: 30px;
    }
  }
  @include mediaQuery($md) {
    padding-bottom: 0px;

    &__content {
      display: flex;
      justify-content: center;

      padding: 30px;
      padding-top: 0;

      :global(.checkout-card-item) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.additionalsContainer {
  display: flex;
  gap: 24px;
  flex-grow: 1;
  flex-wrap: wrap;

  :global(.checkout-card-item) {
    width: calc(50% - 12px);
    min-width: 340px;
    max-width: 440px;
  }
}

.driver {
  display: flex;
  flex-direction: column;
  align-self: center;

  width: 100%;
  margin: auto;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    width: 100%;

    &__row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 24px;

      & > * {
        width: calc(50% - 12px);
      }

      &--addressRow {
        display: flex;
        gap: 24px;
        & > *:nth-child(1) {
          width: 50%;
        }
        & > *:nth-child(2) {
          width: 20%;
        }
        & > *:nth-child(3) {
          width: 30%;
        }
      }

      &--complementRow {
        display: flex;
        gap: 24px;
        & > *:nth-child(1) {
          width: 40%;
        }
        & > *:nth-child(2) {
          width: 40%;
        }
        & > *:nth-child(3) {
          width: 20%;
        }
      }
    }
  }

  &__recaptcha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
      font-size: 14px;
      font-weight: 500;
      color: #ce2e2e;
    }
  }
}

.phoneInput {
  background: white;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 44px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--mobility-color-dark-100);
  color: var(--mobility-color-dark-950);
  font-family: var(--font-outfit);
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  & input {
    border: none;
    &:focus {
      outline: none;
    }
  }

  &:hover {
    outline: none;
    border: 1px solid var(--mobility-color-orange-300);
  }

  &__label {
    width: 100%;
    line-height: 44px;
    color: var(--mobility-color-dark-950);
    font-weight: 500;
    font-size: 0.875rem;
  }
}

@import "src/styles/_variables-mixins.scss";
.testimonials {
  display: flex;

  width: 100%;
  padding: 180px 0;

  background-color: var(--mobility-color-light-100);

  :global(.subtitle),
  :global(.title) {
    text-align: center;
  }

  &__list-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    :global(.button) {
      flex-shrink: 0;

      width: 28px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    width: 100%;
    max-width: 1050px;
    margin-top: 48px;
  }
}

.card {
  display: flex;
  flex-direction: column;

  width: 31%;
  max-width: 350px;
  height: 365px;
  padding: 40px;

  border-radius: 8px;
  border: 1px solid var(--mobility-color-dark-400);
  background: var(--mobility-color-light-50);

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    width: 100%;
    margin-bottom: 24px;

    h4 {
      @include responsiveFontSize(16px, 16px, 14px);
      line-height: 110%;
      color: var(--mobility-color-dark-950);
      font-weight: 700;
    }
    &--role {
      font-size: 14px;
      color: var(--mobility-color-dark-700);
      font-weight: 400;
    }
    &--company {
      font-size: 14px;
      font-weight: 500;
      color: var(--mobility-color-orange-700);
    }
  }

  &__figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 79px;
    height: 79px;

    img {
      position: relative;
      z-index: 2;
    }

    span {
      position: absolute;
      z-index: 3;
      left: 0;
      bottom: 0;

      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    max-height: 210px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    scroll-behavior: smooth;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  :global(.text) {
    max-height: 210px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include mediaQuery($xxl) {
    padding: 25px;
  }
}

.testimonials__video {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

@import "src/styles/_variables-mixins.scss";
.searchResult {
  &__results {
    display: flex;
    flex-direction: column;

    width: 100%;

    li {
      display: flex;
      flex-direction: column;

      width: 100%;
      padding: 24px 0;

      border-bottom: 1px solid var(--mobility-color-dark-400);

      &:last-child {
        border-bottom: 0;
      }

      a {
        transition: all 0.15s;

        &:hover {
          color: var(--mobility-color-orange-600);
        }
      }
    }
  }

  &__breadcrumbs {
    display: flex;
    align-items: center;
    gap: 6px;

    margin-bottom: 8px;

    a,
    span {
      @include responsiveFontSize(16px, 16px, 14px);
      line-height: 150%;
      color: var(--mobility-color-dark-700);
      font-weight: 500;

      &:first-child {
        color: var(--mobility-color-dark-950);
      }
    }

    span {
      font-size: 12px;
    }
  }
}

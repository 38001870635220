@import "src/styles/_variables-mixins.scss";
.sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 24px;

  width: 34%;
  max-width: 420px;
  padding: 40px;

  & > h2 {
    color: var(--mobility-color-dark-700);
    font-weight: 400;
    @include responsiveFontSize(20px, 18px, 16px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  &__car-title {
    color: var(--mobility-color-dark-950);
    font-weight: 700;
    text-transform: uppercase;
    @include responsiveFontSize(20px, 18px, 16px);
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 197px;
  }

  &__title {
    margin-bottom: 16px;

    color: var(--mobility-color-dark-950);
    font-weight: 700;
    @include responsiveFontSize(20px, 18px, 16px);

    small {
      display: flex;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1rem;

    width: 100%;
    padding-bottom: 24px;

    border-bottom: 1px solid var(--mobility-color-dark-400);
  }

  &__extra-list {
    display: flex;
    flex-direction: column;
    gap: 6px;

    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > p {
        color: var(--mobility-color-dark-700);
        font-weight: 600;
        @include responsiveFontSize(16px, 14px, 14px);

        &:last-child {
          color: var(--mobility-color-orange-600);
        }
      }
    }
  }

  &__order-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    p {
      color: var(--mobility-color-dark-700);
      font-weight: 500;
      @include responsiveFontSize(16px, 14px, 14px);
    }
  }

  &__prices {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 12px;

    p {
      color: var(--mobility-color-dark-950);
      font-weight: 600;
      @include responsiveFontSize(18px, 16px, 14px);
    }

    &--daily {
      p {
        color: var(--mobility-color-dark-700);
      }
    }

    &--total {
      p {
        color: var(--mobility-color-orange-700);
        font-weight: 700;
        @include responsiveFontSize(24px, 18px, 14px);
      }
    }
  }

  @include mediaQuery($xxl) {
    padding: 25px;
  }

  @include mediaQuery($xl) {
    padding: 20px;
  }

  @include mediaQuery($lg) {
    width: 100%;
    max-width: 100%;
    padding: 30px 26px;

    border-radius: 0 !important;

    &__content {
      display: flex;
    }

    &--mobile-open {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 125;

      &__content {
        display: flex;
      }
    }
  }

  @include mediaQuery($sm) {
    &--mobile-open {
      align-items: flex-end;
    }
  }
}

.seeDetailsBtn {
  display: none;

  @include mediaQuery($lg) {
    display: flex;
  }
}

.closeBtn {
  display: flex;
  align-self: flex-end;

  border: 0;
  background-color: transparent;
  cursor: pointer;
}

@import "src/styles/_variables-mixins.scss";
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: var(--mobility-color-orange-600);
  font-family: var(--font-outfit);
  text-decoration: none;

  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;

  transition: all 0.2s;

  $this: &;

  &__loader {
    width: 24px;
    height: 24px;
    border: 4px solid #fff;
    border-bottom-color: var(--mobility-color-orange-600);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  &--outlined {
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;

    background-color: transparent !important;
  }
  &--text {
    background-color: transparent !important;
    border: 2px solid transparent !important;
  }
  &--text-underlined {
    background-color: transparent !important;
    border: 2px solid transparent !important;

    & > *:first-child {
      text-decoration: underline;
    }
    & > *:last-child {
      padding-left: 8px;
    }
  }

  &--primary {
    background-color: var(--mobility-color-orange-600);
    border-color: var(--mobility-color-orange-600);
    color: var(--mobility-color-light-50);

    &:hover {
      background-color: var(--mobility-color-orange-500);
      border-color: var(--mobility-color-orange-500);
    }

    &#{$this}--outlined,
    &#{$this}--text,
    &#{$this}--text-underlined,
    &#{$this}--fit {
      color: var(--mobility-color-orange-600);
    }
    &#{$this}--outlined,
    &#{$this}--text {
      &:hover {
        background-color: var(--mobility-color-orange-500) !important;
        color: var(--mobility-color-light-50);
      }
    }

    &#{$this}--fit:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--mobility-color-orange-800) !important;
    }
  }
  &--primary-inverse {
    background-color: var(--mobility-color-light-50);
    border-color: var(--mobility-color-light-50);
    color: var(--mobility-color-orange-600);

    &:hover {
      color: var(--mobility-color-orange-500);
    }

    &#{$this}--outlined,
    &#{$this}--text,
    &#{$this}--text-underlined,
    &#{$this}--fit {
      color: var(--mobility-color-light-50);
    }
    &#{$this}--outlined,
    &#{$this}--text {
      &:hover {
        background-color: var(--mobility-color-orange-100) !important;
        border-color: var(--mobility-color-orange-100) !important;
        color: var(--mobility-color-orange-500) !important;
      }
    }

    &#{$this}--fit:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--mobility-color-light-100) !important;
    }
  }

  &--secondary {
    background-color: var(--mobility-color-dark-950);
    border-color: var(--mobility-color-dark-950);
    color: var(--mobility-color-light-50);

    &:hover {
      background-color: var(--mobility-color-dark-700);
      border-color: var(--mobility-color-dark-700);
    }

    &#{$this}--outlined,
    &#{$this}--text,
    &#{$this}--text-underlined,
    &#{$this}--fit {
      color: var(--mobility-color-dark-950);
    }
    &#{$this}--outlined,
    &#{$this}--text {
      &:hover {
        background-color: var(--mobility-color-dark-950) !important;
        border-color: var(--mobility-color-dark-950) !important;
        color: var(--mobility-color-light-50) !important;
      }
    }

    &#{$this}--fit:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--mobility-color-dark-700) !important;
    }
  }
  &--secondary-inverse {
    background-color: var(--mobility-color-light-50);
    border-color: var(--mobility-color-light-50);
    color: var(--mobility-color-dark-950);

    &:hover {
      background-color: var(--mobility-color-dark-100);
      border-color: var(--mobility-color-dark-200);
    }

    &#{$this}--outlined,
    &#{$this}--text,
    &#{$this}--text-underlined,
    &#{$this}--fit {
      color: var(--mobility-color-light-50);
    }
    &#{$this}--outlined,
    &#{$this}--text {
      &:hover {
        background-color: var(--mobility-color-dark-100) !important;
        color: var(--mobility-color-dark-700) !important;
      }
    }

    &#{$this}--fit:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--mobility-color-dark-300) !important;
    }
  }

  &--alternative {
    background-color: var(--mobility-color-purple-600);
    border-color: var(--mobility-color-purple-600);
    color: var(--mobility-color-light-50);

    &:hover {
      background-color: var(--mobility-color-purple-500);
    }

    &#{$this}--outlined,
    &#{$this}--text,
    &#{$this}--text-underlined,
    &#{$this}--fit {
      color: var(--mobility-color-purple-600);
    }
    &#{$this}--outlined,
    &#{$this}--text {
      &:hover {
        background-color: var(--mobility-color-purple-600) !important;
        color: var(--mobility-color-light-50) !important;
      }
    }

    &#{$this}--fit:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--mobility-color-purple-500) !important;
    }
  }

  &--full {
    width: 100%;
  }

  &--default {
    padding: 16px 24px;

    font-size: calcFontSize(16px);
  }

  &--small {
    padding: 16px;

    font-size: calcFontSize(14px);
  }

  &--thin {
    padding: 8px 16px;

    font-size: calcFontSize(14px);
  }

  &--fit {
    padding: 0;
    background-color: transparent !important;
    border: unset !important;

    font-size: calcFontSize(14px);

    &#{$this}--text,
    &:hover {
      background-color: transparent !important;
    }
  }

  &--radius-default {
    border-radius: 8px;
  }
  &--radius-short {
    border-radius: 4px;
  }

  &--weight-500 {
    font-weight: 500;
  }
  &--weight-600 {
    font-weight: 600;
  }
  &--weight-700 {
    font-weight: 700;
  }

  &--transform-initial {
    text-transform: initial;
  }
  &--transform-uppercase {
    text-transform: uppercase;
  }
  &--transform-lowercase {
    text-transform: lowercase;
  }
  &--transform-capitalize {
    text-transform: capitalize;
  }
  &--disabled {
    pointer-events: none;
    background-color: var(--mobility-color-dark-400);
    border-color: var(--mobility-color-dark-400);
  }
}

@import "src/styles/_variables-mixins.scss";
.badge {
  z-index: 15;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  max-width: 146px;

  padding: 4px 8px;

  border-radius: 4px;
  border: 1px solid transparent;

  font-size: 12px;
  font-weight: 500;

  background-color: #e9eff5;
  color: #7e7e7e;

  &::before {
    display: flex;
    content: '';

    width: 8px;
    height: 8px;
    margin-right: 4px;

    border-radius: 50%;
    background-color: #7e7e7e;
  }

  &--Pending {
    background-color: #ecf2ff;
    color: #007fff;

    &::before {
      background-color: #007fff;
    }
  }

  &--Confirmed {
    background-color: #a5df9f;
    color: #107906;

    &::before {
      background-color: #107906;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.inputContainer {
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;

  $this: &;

  :global(.icon) {
    position: absolute !important;
    z-index: 3;
  }

  &--custom-icon {
    :global(.icon) {
      line-height: 0;

      width: 38px;
      height: 38px;
    }
  }

  &--normal {
    &#{$this}--start-icon {
      :global(.icon) {
        left: 8px;
      }
    }

    &#{$this}--end-icon {
      :global(.icon) {
        right: 8px;
      }
    }
  }

  &--large,
  &--large-alternate {
    &#{$this}--start-icon {
      :global(.icon) {
        left: 16px;
      }
    }

    &#{$this}--end-icon {
      :global(.icon) {
        right: 16px;
      }
    }
  }
}

.input {
  position: relative;
  z-index: 2;

  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--mobility-color-dark-100);

  color: var(--mobility-color-dark-950);
  font-family: var(--font-outfit);
  font-size: 14px;
  font-weight: 500;

  &:hover,
  &:focus {
    border-color: var(--mobility-color-orange-300);
  }
  &:disabled {
    background-color: var(--mobility-color-light-100);
    pointer-events: none;
    &:hover {
      border-color: var(--mobility-color-dark-100);
    }
  }

  $this: &;

  &--phone {
    padding: 0 0 0 12px;
    overflow: hidden;
    input {
      padding: 8px 16px 8px 6px;

      color: var(--mobility-color-dark-950);
      font-family: var(--font-outfit);
      font-size: 14px;
      font-weight: 500;

      border: 0;
      outline: none;

      &:focus {
        outline: none;
      }
    }
  }

  &--normal {
    height: 44px;

    &#{$this}--start-icon {
      padding-left: 36px;
    }

    &#{$this}--end-icon {
      padding-right: 36px;
    }

    &#{$this}--phone {
      input {
        height: 40px;
      }
    }
  }

  &--large {
    height: 52px;

    &#{$this}--start-icon {
      padding-left: 36px;
    }

    &#{$this}--end-icon {
      padding-right: 36px;
    }
  }

  &--large-alternate {
    height: 52px;
    border-radius: 4px;

    &#{$this}--start-icon {
      padding-left: 36px;
    }

    &#{$this}--end-icon {
      padding-right: 36px;
    }
  }

  &--primary {
    background-color: var(--mobility-color-light-50);
  }

  &--alternative {
    background-color: var(--mobility-color-base-gray);
    border-color: var(--mobility-color-base-gray);
  }

  &--error {
    border-color: var(--mobility-color-body-error) !important;
  }
  &--readonly {
    background-color: var(--mobility-color-light-100) !important;
    pointer-events: none;
  }
}

.error {
  display: flex;
  align-items: center;

  margin-top: 8px;

  color: var(--mobility-color-body-error);

  &__text {
    margin-left: 8px;

    font-size: 14px;
    font-weight: 500;
  }
}

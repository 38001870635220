@import "src/styles/_variables-mixins.scss";
// SalesforceForm.module.scss

.createAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--mobility-color-orange-600);

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
    max-width: 600px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      color: rgb(248, 247, 246);
      padding: 0.5rem;
      border-radius: 5px;
      font-size: 0.875rem;
      font-weight: normal;
      width: fit-content;
      margin-bottom: 0.5rem;
    }

    input {
      width: 100%;
      min-width: none;
      padding: 0.5rem;
      border: 2px solid transparent;
      border-radius: 5px;
      font-size: 1rem;

      &:focus {
        border-color: black;
        outline: none;
      }
    }

    p {
      color: rgb(40, 37, 37);
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
  }

  &__submitContainer {
    margin-top: 1rem;
    text-align: center;

    button {
      padding: 0.75rem 2rem;
      font-size: 1rem;
      font-weight: bold;
      color: white;
      background-color: rgb(43, 43, 42);
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darkorange;
      }
    }
  }
}

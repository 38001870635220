@import "src/styles/_variables-mixins.scss";
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  background-color: var(--mobility-color-light-950);
  border-top: 1px solid var(--mobility-color-dark-950);

  &__version {
    position: absolute;
    right: 0;
    color: var(--mobility-color-light-950);
  }

  &__container {
    position: relative;
    display: flex;
    gap: 40px;

    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__moonkey {
    position: absolute;
    right: 0px;
    bottom: 20px;
    z-index: 2;

    display: flex;
    flex-direction: column;

    font-size: 11px;
    color: var(--mobility-color-light-50);
  }

  &__col {
    display: flex;
    flex-direction: column;

    width: 16%;

    &:first-child {
      width: 18%;
    }
    &--wrapper {
      position: relative;
      height: 71px;
    }
  }

  &__about {
    display: flex;
    flex-direction: column;

    margin-top: 26px;
    max-width: 280px;
    padding-right: 30px;

    p {
      margin: 0 0 2px 0;

      color: var(--mobility-color-light-50);
      font-size: 12px;
      font-weight: 500;
      line-height: 23px;

      &:last-child {
        margin-top: 20px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 6px;

    width: 100%;

    border-top: 2px solid var(--mobility-color-orange-600);
    padding: 5px 6px 0 20px;

    li {
      display: flex;

      h4 {
        margin: 15px 0;
        height: 42px;

        color: var(--mobility-color-light-50);
        font-size: 14px;
        font-weight: 700;
      }

      p,
      a {
        color: var(--mobility-color-light-50);
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;

        text-decoration: none;
      }

      a {
        &:hover {
          color: var(--mobility-color-orange-500);
        }
      }
    }
  }

  @include mediaQuery($lg) {
    &__container {
      flex-wrap: wrap;
      gap: 40px;

      padding-top: 40px;
      padding-bottom: 40px;
    }

    &__col {
      display: flex;
      flex-direction: column;

      width: 45%;

      &--wrapper {
        position: relative;
        height: 100%;

        & img {
          position: relative !important;
        }
      }

      &:first-child {
        width: 100%;
      }
    }
    &__about {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      max-width: 100%;
      padding: 0px;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 30%;
        min-width: 180px;
      }

      p {
        &:last-child {
          margin-top: 0;
        }
      }
    }

    &__moonkey {
      right: 30px;
    }
  }
  @include mediaQuery($md) {
    &__col {
      width: 42%;
    }
  }
  @include mediaQuery($md) {
    &__about {
      flex-direction: column;
    }
  }
}

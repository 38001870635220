@import "src/styles/_variables-mixins.scss";
.label {
  margin-bottom: 8px;

  &--normal {
    font-size: calcFontSize(14px);
  }
  &--large {
    font-size: calcFontSize(16px);
  }

  &--weight-400 {
    font-weight: 400;
  }
  &--weight-500 {
    font-weight: 500;
  }
  &--weight-600 {
    font-weight: 600;
  }
  &--weight-700 {
    font-weight: 700;
  }

  &--primary {
    color: var(--mobility-color-dark-950);
  }
  &--secondary {
    color: var(--mobility-color-light-50);
  }
}

@import "src/styles/_variables-mixins.scss";
.selectContainer {
  display: flex;
  flex-direction: column;
}

.trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 44px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--mobility-color-dark-100);

  color: var(--mobility-color-dark-950);
  font-family: var(--font-outfit);
  font-size: calcFontSize(14px);
  font-weight: 500;

  &--primary {
    background-color: var(--mobility-color-light-50);
  }

  &--alternative {
    background-color: var(--mobility-color-base-gray);
    border-color: var(--mobility-color-base-gray);
  }

  &--error {
    border-color: var(--mobility-color-body-error) !important;
  }

  &:hover {
    border-color: var(--mobility-color-orange-300);
  }
  &:focus {
  }
  &[data-placeholder] {
    color: var(--mobility-color-dark-950);
  }

  &__icon {
    line-height: 50%;
  }
}

.select {
  &__content {
    position: relative;
    z-index: 89;
    width: var(--radix-select-trigger-width);
    max-height: var(--radix-select-content-available-height);

    padding: 0;

    overflow: hidden;
    background-color: var(--mobility-color-light-50);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  &__viewport {
    padding: 0;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;

    min-height: 25px;
    padding: 8px 16px;
    user-select: none;

    color: var(--mobility-color-dark-700);
    font-family: var(--font-outfit);
    font-size: calcFontSize(14px);
    font-weight: 400;

    &[data-state='checked'] {
      background-color: var(--mobility-color-orange-100);
    }
    &[data-disabled] {
      color: var(--mauve-8);
      pointer-events: none;
    }
    &[data-highlighted] {
      outline: none;
      background-color: var(--mobility-color-orange-100);
    }
  }

  &__item-indicator {
    position: absolute;
    left: 0;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    & > span {
      font-size: 18px;
    }
  }

  &__scroll-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    background-color: white;
    color: var(--violet-11);
    cursor: default;
  }
}

.error {
  display: flex;
  align-items: center;

  margin-top: 8px;

  color: var(--mobility-color-body-error);

  &__text {
    margin-left: 8px;

    font-size: 14px;
    font-weight: 500;
  }
}

@import "src/styles/_variables-mixins.scss";
.brands {
  display: flex;
  background-color: var(--mobility-color-light-50);
  border-bottom: 10px solid var(--mobility-color-orange-600);
  padding: 40px 0;

  &__noBorder {
    border: 0 !important;
  }

  &__isSaturate {
    img {
      filter: saturate(0);
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    li {
      display: flex;

      img {
        max-width: 100%;
      }
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.carsCarousel {
  $this: &;

  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 80px;

  :global(.section-header) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;

    :global(.icon) {
      font-size: 28px;
    }

    :global(.button) {
      &:hover {
        :global(.icon) {
          color: var(--mobility-color-orange-700);
        }
      }
    }

    @include mediaQuery($md) {
      gap: 8px;

      :global(.icon) {
        font-size: 22px;
      }
    }
  }

  &__list {
    display: flex;
    gap: 24px;

    width: 100%;
    padding: 30px 18px 50px 18px;

    :global(.car-card) {
      box-shadow: 0px 3px 15px 0px rgba(76, 76, 76, 0.2) !important;
    }
  }

  &__dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &__dot {
    display: block;

    width: 16px;
    height: 16px;

    background-color: var(--mobility-color-light-50);
    border-radius: 50%;
    border: 4px solid var(--mobility-color-dark-950);

    text-indent: -9999px;
    color: transparent;
    font-size: 2px;
    cursor: pointer;

    &:hover,
    &--active {
      background-color: var(--mobility-color-orange-700);
      border-color: var(--mobility-color-orange-700);
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.card {
  display: flex;
  gap: 40px;

  width: 100%;
  padding: 40px;

  &__model {
    margin-top: 20px;
    margin-bottom: 40px;

    color: var(--mobility-color-dark-950);
    @include responsiveFontSize(24px, 18px, 14px);
    font-weight: 700;
  }

  &__legend {
    margin-bottom: 16px;

    color: var(--mobility-color-dark-950);
    @include responsiveFontSize(20px, 16px, 14px);
    font-weight: 700;

    &--no-margin {
      margin: 0;
    }
  }

  &__text {
    color: var(--mobility-color-dark-700);
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
  }

  @include mediaQuery($md) {
    flex-direction: column;
    gap: 36px;

    padding: 26px;
  }
}

.colLeft,
.colRight {
  display: flex;
  flex-direction: column;
}

.colLeft {
  width: calc(45% - 20px);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  &__order {
    color: var(--mobility-color-dark-700);
    @include responsiveFontSize(20px, 18px, 14px);
    font-weight: 700;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 280px;

    img {
      width: 100%;
      max-height: 100%;

      object-fit: contain;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

    width: 100%;
  }

  @include mediaQuery($md) {
    width: 100%;

    &__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &__image {
      height: 180px;
    }
  }
}
.colRight {
  width: calc(55% - 20px);

  &__title {
    margin: 3px 0 30px 0;

    text-transform: uppercase;
    color: var(--mobility-color-dark-950);
    @include responsiveFontSize(32px, 28px, 24px);
    font-weight: 700;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;

    &--center {
      flex-direction: row;

      margin: 35px auto;
      padding: 35px 0;

      border-top: 1px solid var(--mobility-color-dark-400);
      border-bottom: 1px solid var(--mobility-color-dark-400);
    }

    &--last {
      margin-top: 35px;
    }

    &--address {
      @include mediaQuery($md) {
        flex-direction: column;

        & > * {
          width: 90% !important;
          max-width: 90%;

          padding: 0 !important;
          margin: 0;
        }
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;

    width: 50%;

    &:first-child {
      padding-right: 20px;
    }
    &:last-child {
      padding-left: 20px;
    }
  }

  @include mediaQuery($md) {
    width: 100%;
  }
}

.extras {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 24px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 14px;

    h4,
    p {
      line-height: 100%;
    }
  }
}

.paymentMethod {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 40px;
}

.prices {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    &--daily {
      justify-content: end;
    }
  }

  &__daily {
    color: var(--mobility-color-dark-950);
    @include responsiveFontSize(16px, 16px, 14px);
    font-weight: 600;
  }

  &__total {
    color: var(--mobility-color-orange-700);
    @include responsiveFontSize(24px, 20px, 18px);
    font-weight: 700;
  }
}

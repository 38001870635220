@import "src/styles/_variables-mixins.scss";
.dateRangePicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  cursor: pointer;

  max-width: 100%;
  height: 52px;
  padding: 17px 15px;

  border-radius: 4px;
  background-color: var(--mobility-color-light-50);

  .divider {
    flex-shrink: 0;
    flex-grow: initial;
    width: 2px;
    height: 18px;

    background-color: var(--mobility-color-dark-100);
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    padding: 8px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  @include mediaQuery($md) {
    li {
      p {
        font-size: 12px;
      }
    }
  }
  @include mediaQuery($sm) {
    li {
      p {
        max-width: 45px;
        overflow: hidden;
      }
    }
  }
}

.twoMonths {
  display: flex !important;
  gap: 1rem;

  & > div {
    min-width: 280px !important;
  }

  .react-datepicker__month-container {
    float: none !important;
  }
}

.datePickerContainer {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  background: white;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.datePickerWrapper {
  h4 {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: var(--mobility-color-dark-900);
  }
}

.popoverContent {
  z-index: 50;
  border-radius: 4px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  overflow: hidden;
}

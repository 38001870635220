@import "src/styles/_variables-mixins.scss";
.header {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 88px;
  padding: 0 80px;

  background-color: var(--mobility-color-light-50);

  & > nav {
    height: 100%;
  }

  &--black {
    background-color: var(--mobility-color-dark-950);
  }
  &--orange {
    background-color: var(--mobility-color-orange-600);

    :global(.menu-item:hover) {
      :global(.text-link) {
        border-color: var(--mobility-color-black-950);
      }
    }
  }

  &--fixed {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;

    background-color: transparent !important;
  }

  &--mobile-open {
    & > nav {
      position: absolute;
      z-index: 119;
      top: 88px;
      left: 0;
      right: 0;

      height: auto;

      background-color: var(--mobility-color-light-50);
    }
  }

  @include mediaQuery($xxl) {
    padding: 0 60px;
  }
  @include mediaQuery($xl) {
    padding: 0 30px;
  }
  @include mediaQuery($lg) {
    & > nav {
      display: none;

      padding-bottom: 30px;
    }
    &--mobile-open {
      & > nav {
        display: block;
      }
    }

    :global(.vehicle-category-card) {
      flex-direction: row;
      justify-content: flex-start;

      img {
        width: 150px;

        object-fit: contain;
      }

      p {
        width: auto;
        margin: 0 0 0 16px;
      }
    }
  }
}

.logo {
  position: relative;
  z-index: 101;

  width: 20%;
  min-width: 268px;

  @include mediaQuery($xl) {
    min-width: 140px;
    max-width: 170px;
  }
}
.actionsMenu {
  position: relative;
  z-index: 101;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;

  width: 20%;
  min-width: 268px;

  @include mediaQuery($xl) {
    min-width: 258px;
    gap: 30px;
  }

  @include mediaQuery($lg) {
    display: none;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    padding: 20px 30px;
    margin-top: 16px;

    :global(.button) {
      width: 100%;
    }
  }
}

.userWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  cursor: pointer;

  &__avatar {
    border-radius: 50%;
    overflow: hidden;
  }
}

.toggleMenu {
  display: none;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--mobility-color-orange-100);
    color: var(--mobility-color-orange-600);
  }

  &--secondary-inverse {
    color: var(--mobility-color-light-50);
  }

  @include mediaQuery($lg) {
    display: flex;
  }
}

.menu {
  display: flex;
  gap: 0 24px;

  height: 100%;

  @include mediaQuery($xl) {
    gap: 0 14px;
  }

  @include mediaQuery($lg) {
    flex-direction: column;

    width: 100%;
  }
}
.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 10px 12px;

  color: var(--mobility-color-dark-950);

  cursor: pointer;

  & > .textLink {
    position: relative;
    z-index: 101;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 40px;

    @include responsiveFontSize(16px, 14px, 16px);

    border-bottom: 2px solid transparent;

    & > span {
      padding-left: 8px;
      font-size: 20px;
    }

    @include mediaQuery($lg) {
      justify-content: space-between;

      height: auto;
      padding-left: 15px;
      padding-right: 15px;

      cursor: pointer;
    }
  }

  &--open,
  &:hover {
    & > .textLink {
      border-color: var(--mobility-color-orange-600);
    }
  }

  &--inverse {
    color: var(--mobility-color-light-50);
  }

  @include mediaQuery($xl) {
    padding: 10px;
  }
  @include mediaQuery($lg) {
    align-items: flex-start;
  }
  @include mediaQuery($md) {
    &--inverse {
      color: var(--mobility-color-dark-950);
    }
  }
}
.hasMegaMenu {
  cursor: default;

  &:hover {
    .megaMenu {
      top: 100%;

      visibility: visible;
      opacity: 1;
    }
  }

  @include mediaQuery($lg) {
    flex-direction: column;

    padding: 15px 10px;
  }
}

.megaMenu {
  position: absolute;
  z-index: 90;
  top: -10%;
  left: 0;
  right: 0;

  visibility: hidden;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 60px 0;
  background-color: var(--mobility-color-light-50);

  transition:
    opacity 0.25s ease,
    top 0.3s ease;

  & > * {
    font-family: var(--font-roboto);
  }

  h4 {
    margin-bottom: 1rem;

    font-weight: 700;
  }

  a {
    font-weight: 400;
  }

  @include mediaQuery($xxl) {
    padding: 60px 0;
  }
  @include mediaQuery($xl) {
    padding: 50px 0;
  }
  @include mediaQuery($lg) {
    display: none;

    position: relative;
    z-index: 121;
    top: auto;
    left: auto;
    right: auto;

    width: 100%;
    padding: 20px 0;

    visibility: visible;
    opacity: 1;

    &--mobile-open {
      display: flex;
    }
  }
}

.megaMenuWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px 48px;

  width: 100%;

  color: var(--mobility-color-dark-950);

  &--categories {
    gap: 16px;
    overflow: hidden;

    & > * {
      width: 100%;
      max-width: 214px;
    }
  }

  @include mediaQuery($lg) {
    flex-direction: column;
    gap: 48px 15px;

    &--categories {
      & > * {
        max-width: unset;
      }
    }
  }
}

.megaMenuItem {
  display: flex;
  flex-direction: column;

  width: 240px;

  color: var(--mobility-color-dark-950);

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      a {
        display: flex;
        align-items: center;
      }
    }
    & .seeMore {
      a {
        font-family: var(--font-outfit);
        font-weight: 700;
        color: var(--mobility-color-orange-400);

        & > span {
          &:first-child {
            text-decoration: underline;
          }
          &:last-child {
            padding-left: 8px;
          }
        }
      }
    }
  }
}

.megaMenuHighlight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: auto;

  img {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
  }

  h3 {
    font-family: var(--font-outfit);
    font-size: calcFontSize(20px);
    font-weight: 700;
  }

  p {
    margin: 12px 0;

    font-weight: 400;
  }

  a {
    display: flex;
    align-items: center;

    font-family: var(--font-outfit);
    font-weight: 600;
    color: var(--mobility-color-orange-400);
    text-transform: uppercase;

    & > span {
      padding-left: 1rem;
    }
  }
}

.megaMenuFooter {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding-top: 48px;

  a {
    display: flex;
    align-items: center;

    font-weight: 700;
  }
}

.actionsMenuMobile {
  display: none;

  @include mediaQuery($lg) {
    display: block;

    & > * {
      display: flex;
    }
  }
}

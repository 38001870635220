@import "src/styles/_variables-mixins.scss";
.listCollapse {
  margin-top: 140px;
  margin-bottom: 140px;

  h2 {
    b {
      color: var(--mobility-color-orange-700);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;

    margin-top: 40px;
    width: 100%;
  }
}

.collapseItem {
  padding: 24px 16px;

  border-left: 2px solid var(--mobility-color-dark-400);

  $this: &;
  &--opened {
    border-color: var(--mobility-color-dark-950);

    #{$this}__title {
      color: var(--mobility-color-dark-950);
    }
  }

  &__title {
    color: var(--mobility-color-dark-400);
    background-color: transparent;
    border: 0;
  }

  @include mediaQuery($md) {
    &__title,
    :global(.text) {
      text-align: left;
    }
  }
}

.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

@import "src/styles/_variables-mixins.scss";
.postCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  width: 100%;

  &__date-category {
    display: block;

    margin-top: 40px;
  }

  &__title {
    line-height: 110%;
    word-wrap: break-word;
  }

  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @include mediaQuery($xxl) {
      -webkit-line-clamp: 3;
    }
  }

  &__cover {
    aspect-ratio: 0.83 / 1;
    background-size: cover;
    display: flex;
    border-radius: 8px;
  }

  @include mediaQuery($md) {
    &__date-category {
      margin-top: 20px;
    }
  }
}

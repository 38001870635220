@import "src/styles/_variables-mixins.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.titleSkeleton {
  width: 25%;
  height: 2rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
  margin-bottom: 5rem;
}

.skeletonBookingCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  max-width: 440px;
  min-height: 604px;
  min-width: 440px;
  padding: 24px;
  background-color: var(--mobility-color-light-50);
  border-radius: 16px;
}

.skeletonCard {
  box-sizing: border-box;
  margin: 0;
  border-radius: 16px;
  max-width: 440px;
  min-height: 604px;
  background-color: var(--mobility-color-light-50);
  box-shadow: rgba(76, 76, 76, 0.15) 0px 4px 30px 0px;
}

.subtitleSkeleton {
  width: 20%;
  height: 1rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.boardSkeleton {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cardsContainerSkeleton {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.itemSkeleton {
  width: 90%;
  height: 3rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
}

.itemTitleSkeleton {
  width: 30%;
  height: 1rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
}

.itemImageSkeleton {
  width: 100%;
  height: 10rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
}

.itemDescriptionTitleSkeleton {
  width: 70%;
  height: 0.8rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
}

.itemDescriptionSkeleton {
  width: 55%;
  height: 0.8rem;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
}

.divisorSkeleton {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #d3d3d3 25%, #e0e0e0 50%, #d3d3d3 75%);
  background-size: 200% 100%;
  animation: slide 1.5s infinite;
  border-radius: 0.5rem;
}

@keyframes slide {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .titleSkeleton {
    width: 50%;
  }

  .subtitleSkeleton {
    width: 40%;
  }

  .skeletonBookingCard,
  .skeletonCard {
    max-width: 100%;
    min-width: 100%;
  }

  .cardsContainerSkeleton {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .titleSkeleton {
    width: 70%;
  }

  .subtitleSkeleton {
    width: 60%;
  }

  .itemSkeleton,
  .itemTitleSkeleton,
  .itemImageSkeleton,
  .itemDescriptionTitleSkeleton,
  .itemDescriptionSkeleton {
    width: 100%;
  }
}

@import "src/styles/_variables-mixins.scss";
.faqCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 40px;

  border: 1px solid var(--mobility-color-dark-400);
  border-radius: 8px;

  img {
    width: 85px;
    height: 85px;
    margin-bottom: 24px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @include mediaQuery($md) {
    padding: 30px;
  }
}

@import "src/styles/_variables-mixins.scss";
.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 24px;

  @include mediaQuery($md) {
    margin-bottom: 24px;
  }
}

@import "src/styles/_variables-mixins.scss";
.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: #000000ab;
  .modal {
    padding: 64px 40px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    width: 966px;
    height: 412px;

    .modal__title {
      font-size: 32px;
      font-weight: 700;
      color: #000000;
    }

    .modal__message {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      margin-top: 24px;
    }

    .group_btn {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 40px;
      gap: 20px;
    }
  }
}

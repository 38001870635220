@import "src/styles/_variables-mixins.scss";
.disclaimer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 30px;
  margin-top: 30px;

  background-color: var(--mobility-color-orange-100);
  border-radius: 16px;

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: var(--mobility-color-dark-950);
    margin-bottom: 8px;
  }
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--mobility-color-dark-700);
  }

  @include mediaQuery($md) {
    flex-direction: column;

    :global(.button) {
      width: 100%;
      margin-top: 24px;
    }
  }
}

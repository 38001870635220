@import "src/styles/_variables-mixins.scss";
.search {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 40px;
  margin-top: -60px;
  margin-bottom: 90px;

  border-radius: 24px;
  background-color: var(--mobility-color-dark-950);

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    gap: 16px;

    :global(.form-input) {
      display: flex;
      flex-grow: 1;
    }
    :global(.button) {
      height: 44px;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;

    padding: 24px;

    &__form {
      flex-direction: column;
      align-items: flex-start;

      width: 100%;

      :global(.form-input),
      :global(.button) {
        width: 100%;
      }
    }
  }
}

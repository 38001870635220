@import "src/styles/_variables-mixins.scss";
.container {
  width: 100%;
  height: 100%;
  min-height: 565px;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
}

.loader {
  width: calc(100% / 3);
  background-color: #fff;
  min-height: 565px;
  height: 100%;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 30px 0px rgba(76, 76, 76, 0.15);
}

@media (max-width: 768px) {
  .loader {
    width: 100%;
  }
  .container {
    flex-direction: column;
  }
}

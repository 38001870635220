@import "src/styles/_variables-mixins.scss";
.categoryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    width: 100%;
    margin-top: 24px;

    text-align: center;
    font-size: calcFontSize(16px);
    font-weight: 600;
    color: var(--mobility-color-dark-950);
  }
}

@import "src/styles/_variables-mixins.scss";
.searchPage {
  width: 100%;

  padding-bottom: 120px;

  &__search {
    background-color: var(--mobility-color-dark-900);

    :global(.search-component) {
      background-color: var(--mobility-color-dark-900);
      padding: 40px 0;
    }
  }
}

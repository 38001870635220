@import "src/styles/_variables-mixins.scss";
.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 40px;

  button {
    :global(.icon) {
      margin-left: 16px;
    }
  }

  &__title {
    span {
      color: var(--mobility-color-orange-700);
    }
  }

  @include mediaQuery($md) {
    & > div:first-child {
      max-width: 65%;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  width: 100%;
  margin: -60px 0 60px 0;

  li {
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 47px;
    height: 40px;

    font-size: 14px;
    font-weight: 400;
    color: var(--mobility-color-dark-700);

    border-radius: 4px;

    &:hover,
    &--active {
      background-color: var(--mobility-color-purple-600);
      color: var(--mobility-color-light-50);
    }

    &--no-link {
      &:hover {
        background-color: transparent !important;
        color: var(--mobility-color-dark-700) !important;
      }
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.travelItinerariesList {
  margin: 125px 0;

  @include mediaQuery($md) {
    :global(.section-header) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      & > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.list {
  display: flex;
  gap: 40px;

  width: 100%;

  &__item {
    display: flex;
    flex-direction: column;

    width: calc(50% - 20px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    padding: 40px;

    background-color: #191919;

    p {
      margin: 8px 0 60px 0;
    }

    & > button {
      & > span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 36px;
        height: 36px;
        margin-left: 16px;

        border-radius: 50%;
        color: var(--mobility-color-light-50);
        background-color: var(--mobility-color-orange-600);
      }
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;

    &__item {
      width: 100%;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.header_actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.header_actions__button {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 5px;
  }
}

.header_link {
  display: flex;
  align-items: center;

  margin-bottom: 48px;

  :global(.title) {
    margin-bottom: 0 !important;
  }

  :global(.icon) {
    margin-right: 16px;
  }
}

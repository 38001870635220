@import "src/styles/_variables-mixins.scss";
.features {
  display: flex;
  justify-content: space-between;
  gap: 60px;

  width: 100%;

  &__img {
    width: calc(48% - 30px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: calc(52% - 30px);
    margin: 0 !important;
  }

  @include mediaQuery($md) {
    flex-direction: column;

    &__img {
      width: 100%;
    }

    &__content {
      width: 100%;
    }
  }
}

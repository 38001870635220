@import "src/styles/_variables-mixins.scss";
.checkboxWrapper {
  position: relative;
  display: flex;
  align-items: center;

  input {
    opacity: 0;
    visibility: hidden;

    &:checked ~ .checkbox {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M382-253.847 168.616-467.231l42.769-42.768L382-339.384l366.615-366.615 42.769 42.768L382-253.847Z"/></svg>');
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  span {
    margin-left: 8px;
    margin-bottom: 0;
  }

  :global(.form-label) {
    a {
      color: var(--mobility-color-orange-700);
    }
  }
}

.checkbox {
  display: flex;
  flex-shrink: 0;

  margin: 0 !important;

  border-radius: 8px;
  border: 1px solid var(--mobility-color-dark-100);

  &:hover,
  &:focus {
    border-color: var(--mobility-color-orange-300);
  }

  &--normal {
    width: 22px;
    height: 22px;
  }
  &--large {
    width: 22px;
    height: 22px;
  }

  &--primary {
    background-color: var(--mobility-color-light-50);
  }
  &--secondary {
    background-color: var(--mobility-color-dark-950);
  }
  &--error {
    border-color: var(--mobility-color-body-error) !important;
  }
}

@import "src/styles/_variables-mixins.scss";
.threeSteps {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 145px 0;
  margin: 160px 0;

  background-color: var(--mobility-color-light-100);

  * {
    text-align: center;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 100px;

    margin-top: 80px;
    width: 100%;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 33.33%;
      padding: 0 15px;

      h4 {
        margin: 20px 0;

        font-size: calcFontSize(24px);
        font-weight: 800;
        color: var(--mobility-color-dark-950);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 60px;
    height: 60px;

    border-radius: 6px;
    border: 2px solid var(--mobility-color-orange-700);

    & > span {
      width: 36px;
      height: 36px;
    }
  }

  @include mediaQuery($md) {
    &__list {
      flex-direction: column;
      align-items: center;
      gap: 60px;

      li {
        width: 100%;
      }
    }
  }
}

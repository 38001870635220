@import "src/styles/_variables-mixins.scss";
.textareaContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.textarea {
  position: relative;
  z-index: 2;

  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--mobility-color-dark-100);

  color: var(--mobility-color-dark-950);
  font-family: var(--font-outfit);
  font-size: calcFontSize(14px);
  font-weight: 500;

  &:hover,
  &:focus {
    border-color: var(--mobility-color-orange-300);
  }

  $this: &;

  &--primary {
    background-color: var(--mobility-color-light-50);
  }

  &--alternative {
    background-color: var(--mobility-color-base-gray);
    border-color: var(--mobility-color-base-gray);
  }

  &--error {
    border-color: var(--mobility-color-body-error) !important;
  }
}

.textareaWrapper {
  position: relative;
}

.counter {
  position: absolute;
  right: 10px;
  bottom: 5px;
  z-index: 5;

  font-size: 14px;
  font-weight: 400;
  color: var(--mobility-color-dark-700);
}

.error {
  display: flex;
  align-items: center;

  margin-top: 8px;

  color: var(--mobility-color-body-error);

  &__text {
    margin-left: 8px;

    font-size: 14px;
    font-weight: 500;
  }
}

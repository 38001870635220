@import "src/styles/_variables-mixins.scss";
.emptySearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;
  padding: 40px;
  margin: 0 auto;

  &__vector {
    display: block;

    width: 90%;
    max-width: 650px;
  }
}

@import "src/styles/_variables-mixins.scss";
.myOrders {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  margin: 48px 0;

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    width: 100%;

    h1 {
      font-size: large;
    }

    h3 {
      font-size: medium;
      color: var(--mobility-color-dark-700);
    }
  }
}
.toggleButton {
  background: none;
  border: none;
  color: #2f3030;
  cursor: pointer;
  font-size: 16px;
  margin-right: 8px;
}
.emptyOrders {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  width: 100%;
  margin: 48px 0;

  &__image {
    width: 100%;
    max-width: 300px;
    margin-right: 30px;
  }

  h1 {
    font-size: large;
  }

  h3 {
    font-size: medium;
    color: var(--mobility-color-dark-700);
  }

  @include mediaQuery($sm) {
    display: flex;
    flex-direction: column;
  }
}

@import "src/styles/_variables-mixins.scss";
.experiences {
  width: 100%;

  &__header {
    display: flex;

    width: 100%;
    min-height: 300px;
    padding: 120px 0 85px 0;

    background-color: var(--mobility-color-dark-950);

    :global(.container) {
      display: flex;
      justify-content: space-between;
    }

    :global(.icon) {
      color: var(--mobility-color-light-50);
    }
  }

  &__hero {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 450px;

    background-color: var(--mobility-color-dark-950);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &:after {
      position: absolute;
      z-index: 3;
      inset: 0;

      width: 100%;
      height: 100%;

      content: '';
      background: rgba(0, 0, 0, 0.6);
    }

    img {
      object-fit: cover;
    }

    h1 {
      position: relative;
      z-index: 5;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 120px;

    width: 100%;
    margin-top: 95px;

    & > div {
      display: flex;
      flex-direction: column;

      width: calc(50% - 60px);
    }
  }

  @include mediaQuery($md) {
    &__content {
      flex-direction: column;
      gap: 60px;

      margin-top: 80px;

      & > div {
        width: 100%;
      }
    }
  }
}

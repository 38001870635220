@import "src/styles/_variables-mixins.scss";
.list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  margin: 24px 0;

  li {
    width: 24%;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;

    li {
      width: 100%;
      margin-right: 0;
      margin-bottom: 42px;
    }
  }
}

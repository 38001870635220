@import "src/styles/_variables-mixins.scss";
.carCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  width: 100%;
  max-width: 440px;
  min-height: 438px;
  padding: 24px;

  border-radius: 16px;
  cursor: pointer;

  :global(.car-features) {
    padding-top: 16px;

    border-top: 1px solid var(--mobility-color-light-300);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}
.img {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 186px;

  & > img {
    width: auto;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: 98px;

  & > p,
  & > h5 {
    font-size: 14px;
  }

  & > p {
    color: var(--mobility-color-dark-400);
    font-weight: 400;
  }

  & > h4 {
    font-size: 20px;
    color: var(--mobility-color-orange-700);
    font-weight: 700;
  }

  &__title {
    display: inline-flex;

    width: 100%;

    color: var(--mobility-color-dark-950);
    font-weight: 800;
    text-transform: uppercase;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@import "src/styles/_variables-mixins.scss";
.latestPostsByCategory {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 80px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    :global(.icon) {
      margin-left: 16px;
    }
  }

  @include mediaQuery($md) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  margin: 24px 0;

  li {
    width: 25%;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.searchPage {
  width: 100%;

  &__header {
    display: flex;

    padding: 160px 0 30px 0;
    width: 100%;
    height: 390px;

    background-color: var(--mobility-color-dark-950);

    h1,
    p {
      text-align: center;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;

    margin-top: 63px;
    margin-bottom: 48px;
    width: 100%;

    p {
      margin-bottom: 16px;
    }
  }

  :global(.search-component) {
    margin-top: -81px;
    margin-bottom: 50px;
  }

  @include mediaQuery($md) {
    &__header {
      padding: 70px 0 30px 0;
      height: 250px;
    }
    :global(.search-component) {
      margin-top: 0;
    }
  }
}

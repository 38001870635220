@import "src/styles/_variables-mixins.scss";
.blogPostsList {
  margin-top: 140px;
  margin-bottom: 140px;

  &__image {
    border-radius: 16px;
  }

  @include mediaQuery($md) {
    :global(.section-header) {
      flex-direction: column;

      & > * {
        max-width: 100% !important;
      }

      :global(.button) {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}

.postHighlight {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  width: 100%;
  margin: 40px 0;

  &__image {
    width: calc(50% - 40px);

    border-radius: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin: 10px 0;
    }
    p {
      margin-bottom: 16px;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;

    &__image {
      width: 100%;
    }

    :global(.button) {
      margin-top: 16px;
    }
  }
}

.posts {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 24px;

    h3 {
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;

    :global(.icon) {
      font-size: 28px;
    }
  }

  &__list {
  }

  &__item {
    display: flex;
    flex-direction: column;

    img {
      margin-bottom: 16px;
    }

    h4 {
      margin: 8px 0;

      font-size: calcFontSize(24px);
      font-weight: 700;
      color: var(--mobility-color-dark-950);
    }
  }
}

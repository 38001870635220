@import "src/styles/_variables-mixins.scss";
.modal {
  :global(.modal-content) {
    max-width: 700px;
  }
}

.container {
}

.content {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 32px 0;

  &:first-child {
    border-bottom: 1px solid var(--mobility-color-dark-400);
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 16px;

  font-size: 16px;
  font-weight: 600;
  color: var(--mobility-color-dark-700);
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;

  span {
    font-weight: 400;
  }

  &--active,
  &:hover {
    color: var(--mobility-color-orange-600);
    border-color: var(--mobility-color-orange-600);
  }
}

@import "src/styles/_variables-mixins.scss";
.features {
  display: flex;
  align-items: center;
  gap: 22px;

  width: 100%;
  margin: 144px 0 157px 0;

  &__img {
    display: flex;
    flex-shrink: 0;

    width: 50%;
    max-width: 600px;

    border-radius: 24px;
    overflow: hidden;
  }

  &__content {
  }

  @include mediaQuery($md) {
    flex-direction: column;

    &__img {
      width: 100%;
      max-width: 100%;
    }

    &__content {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
  }
}

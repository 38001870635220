@import "src/styles/_variables-mixins.scss";
.section {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.postsList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  width: 100%;
  margin: 24px 0;

  :global(.post-card) {
    max-width: calc(25% - 9px);

    margin-bottom: 120px;
  }

  @include mediaQuery($md) {
    flex-direction: column;

    :global(.post-card) {
      max-width: 100%;

      margin-bottom: 30px;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.fallback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 115px 0;

  width: 100%;
}

@import "src/styles/_variables-mixins.scss";
.search {
  position: relative;
  z-index: 12;
  display: flex;
  align-items: flex-end;
  gap: 16px;

  width: 100%;
  padding: 40px 40px 20px 40px;

  border-radius: 24px;
  backdrop-filter: blur(10px);

  &--primary {
    background-color: var(--mobility-color-light-950);
  }
  &--secondary {
    background-color: var(--mobility-color-light-300);
  }

  input[type='text'] {
    border-radius: 4px;
  }

  & > button {
    height: 52px;
  }

  @include mediaQuery($md) {
    flex-direction: column;

    padding: 32px 20px;

    :global(.button) {
      width: 100%;
    }
  }
  @include mediaQuery($sm) {
    border-radius: 0;
  }
}

.searchLocations {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  max-width: 682px;

  & > div {
    flex-grow: 1;

    width: auto;
    max-width: unset;
  }

  &--with-checkbox {
    display: flex;
    flex-direction: column;

    padding-left: 2px;

    & > label {
      margin-bottom: 8px;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;

    width: 100%;

    & > div {
      width: 100%;
      max-width: 100%;
    }

    &--with-checkbox {
      flex-direction: column-reverse;

      margin-top: 12px;
      padding-left: 0;

      & > label {
        margin-top: 8px;
      }

      & > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.arrowsSwitch {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
  margin: 26px 2px 18px 2px;

  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;

  @include mediaQuery($md) {
    position: absolute;
    z-index: 10;

    margin: -10px 0 0 0;

    background-color: var(--mobility-color-light-950);
  }
}

.searchDates {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 30px;
  max-width: 100%;

  @include mediaQuery($md) {
    width: 100%;
  }
}

.labelFooter {
  padding: 5px;
  margin-bottom: -2px !important;
  display: flex;
  justify-content: flex-end;
}

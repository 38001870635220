@import "src/styles/_variables-mixins.scss";
.presence {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 88px;

  width: 100%;

  &__col-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 16px 0;
    }
  }

  &__col-right {
    position: relative;

    display: flex;
    flex-shrink: 0;
    overflow: hidden;

    width: 50%;
    min-height: 345px;

    border-radius: 24px;

    img {
      inset: 0;
      height: 100%;

      object-fit: cover;
    }
  }

  @include mediaQuery($md) {
    flex-direction: column;
    gap: 40px;

    &__col-left,
    &__col-right {
      width: 100%;
    }
  }
}

@import "src/styles/_variables-mixins.scss";
.banner {
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;

  width: 100%;
  height: 786px;
  margin-bottom: 120px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  :global(.container) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    max-width: 550px;
  }

  h1 {
    position: relative;
    z-index: 10;

    width: 100%;

    color: var(--mobility-color-light-50);
    @include responsiveFontSize(64px, 58px, 50px);
    line-height: 110%;
    font-weight: 700;
  }

  h4 {
    position: relative;
    z-index: 11;

    width: 100%;
    margin-bottom: 40px;

    color: var(--mobility-color-light-50);
    @include responsiveFontSize(20px, 28px, 16px);
    font-weight: 500;
  }

  @include mediaQuery($md) {
    height: 545px;

    background-position: 0 0;
  }
}

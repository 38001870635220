@import "src/styles/_variables-mixins.scss";
.postsCarousel {
  $this: &;

  margin-top: 140px;
  margin-bottom: 140px;

  &__list {
    display: flex;
    gap: 24px;

    width: 100%;
  }

  &__card {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;

    width: calc(25% - 18px);
    max-width: 25%;
    flex-shrink: 0;

    &:hover #{$this}__link {
      align-items: center;

      height: 120px;
      padding: 24px;

      background-color: var(--mobility-color-dark-950);
      color: var(--mobility-color-light-50);
    }
    &:hover #{$this}__icon {
      opacity: 1;
    }
  }

  &__image {
    position: relative;
    z-index: 2;
    display: flex;
    flex-shrink: 0;

    width: 100%;
    height: 418px;

    img {
      object-fit: cover;
    }
  }

  &__link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    height: 48px;
    width: 100%;

    font-size: calcFontSize(24px);
    font-weight: 500;
    color: var(--mobility-color-dark-700);

    transition:
      height 0.25s ease,
      padding 0.1s ease;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 37px;
    height: 37px;

    border-radius: 50%;
    background-color: var(--mobility-color-orange-600);
    color: var(--mobility-color-light-50);
    opacity: 0;

    transition: opacity 0.1s ease;
  }

  @include mediaQuery($xl) {
    &__card {
      width: calc(33% - 18px);
      max-width: 33.33%;
    }
  }
  @include mediaQuery($md) {
    padding: 0 16px !important;

    margin-top: 60px;
    margin-bottom: 60px;

    &__card {
      width: 100%;
      max-width: 100%;
    }
  }
}

.arrows {
  display: flex;
  align-items: center;
  gap: 16px;

  :global(.icon) {
    font-size: 28px;
  }

  @include mediaQuery($md) {
    gap: 8px;

    :global(.icon) {
      font-size: 22px;
    }
  }
}
